<template>

  <div
    class="app-intro"
    role="rich-text">

    <p
      class="title">CODC Global Ocean Science Database (CODC-GOSD)
    </p>

    <div
      class="rich-text">

      <p>CODC Global Ocean Science Database (CODC-GOSD) is a global ocean field observation data product constructed by the Oceanographic Data Center, Chinese Academy of Sciences(CASODC). CODC has collected a large number of global ocean observation data since 1900, including 13 physical or biogeochemical elements such as seawater temperature, salinity, pH, dissolved oxygen, CO2 partial pressure, etc., as well as 11 instrument types such as XBT, CTD, Argo, glider, buoy, etc. In CODC-GOSD, there are tens of millions of profiles for temperature and salinity data each. CODC-GOSD data sources include NOAA-WOD, China's independent observation data, etc.
      </p>

      <p>CODC has developed a characteristic ocean observation data quality control system: CODC-QC, which systematically controls the quality of the original observation system. CODC-GOSD also corrects the systematic errors in historical ocean data, including XBT, MBT, and Bottle, which greatly improves the accuracy of the data.
      </p>

      <p>CODC-GOSD is updated in quasi real time to realize the real-time monitoring of marine environment.
      </p>

      <p>CODC-GOSD is publicly shared on this website and can be downloaded in various ways. Data can be referenced through DOI / CSTR.
      </p>

      <p>For data sharing and downloading methods, please refer to these websites for data sharing:
      </p>

      <p>
        <span>Observation data products </span>
        <router-link
          to="/search-field">http://www.casodc.com/search-field
        </router-link>
      </p>

      <p>
        <span>Characteristic data products </span>
        <router-link
          to="/search-field">http://www.casodc.com/search
        </router-link>
      </p>

      <p>
        <span>Special products </span>
        <router-link
          to="/data/special">http://www.casodc.com/data/special
        </router-link>
      </p>

      <h3>Characteristic data products
      </h3>

      <p>The Institute of Oceanography of the Chinese Academy of Sciences has an advanced scientific investigation fleet and ocean observation network. After decades of development, the scientific investigation fleet has grown from nothing to have, and has developed from the world From "science one" to the latest generation of "science" marine science comprehensive investigation ship, we have constructed the buoy observation station in the Yellow Sea and the East China Sea, Jiaozhou Bay field observation station and China's own deep-sea real-time scientific observation network in the Western Pacific Ocean. We have independently acquired and accumulated a large number of valuable marine observation data from the shallow sea to the deep-sea ocean, forming a marine science data resource pool.
      </p>

      <p>The collected data include: scientific research ship voyage survey data, buoy fixed-point observation, deep-sea submarine real-time observation, satellite remote sensing, numerical simulation results and other data, covering 11 categories: CTD, ADCP, hypock navigation, ROV video, multi beam, automatic weather station, shallow profile, multi-channel seismic, chemical analysis data, biological survey data, fixed-point observation, etc. Regional coverage: "two oceans and one sea" (Western Pacific, Indian Ocean and South China Sea), Yellow Sea and East China Sea, Yangtze River Estuary, Bohai Sea, etc. the earliest data can be traced back to 2006. The details are as follows:
      </p>

      <p>1. Voyage survey data
      </p>

      <p>The strategic leading science and technology project of the Chinese Academy of Sciences (Class A), "material and energy exchange in the tropical western Pacific Ocean system and its impact" (hereinafter referred to as "ocean project"), which is oriented to the major national strategic needs and the forefront of international marine science and technology, carries out the voyage survey with scientific research vessels, and collects 118 special survey voyage data, including: the voyage of the Western Pacific Ocean, the strengthened voyage of the Yangtze River Estuary, and the Bohai Sea Survey voyage data and South China Sea and Indian ocean voyage data.
      </p>

      <p>Based on the survey data of 24 open voyages in the Yellow Sea and East China Sea conducted by NSFC from 2006 to 2014, the main observation elements include CTD, ADCP and meteorology, and some voyages submitted the information of dissolved oxygen, chlorophyll, five nutrients, pH, latent buoy and other elements.
      </p>

      <p>2. Fixed point observation data
      </p>

      <p>Fixed point observation data of buoy and submarine buoy, including: 20 sets of observation buoy data of Huanghai and Donghai stations of offshore observation network since 2007, buoy system has realized real-time, dynamic and continuous observation of marine meteorological parameters, hydrological parameters and water quality parameters of China's offshore waters; vertical profile and submarine buoy data of Zhangzi Island's own 21 sets of submarine buoy system, observation parameters include profile The Western Pacific scientific observation network collects 74 sets of data including "two oceans and one sea" (Western Pacific, Indian Ocean and South China Sea), some of which realize real-time and dynamic data transmission and collect physical ocean data such as temperature, salinity and ocean current.
      </p>

      <p>3. Ocean model output data
      </p>

      <p>The Swan + ADCIRC model data of offshore typhoon disaster from 1986 to 2018 is 85.8gb, including the regional sea surface height, wave height, typhoon center point, air pressure and wind field data. Based on ROMs model, the output data of the Western Pacific Ocean is 3.7gb, including salinity, sea level height, temperature and ocean current data of 32 water layers in China's coastal waters, and oil spill path model data of 27 water layers predicted by Sankey's path, with horizontal resolution of 4km. Global ocean current model data based on glom model is 2.4GB, with horizontal resolution of 0.1 degree, including global ocean current data of surface layer and global sea surface temperature.
      </p>

      <p>4. Coastal zone data
      </p>

      <p>It covers hydrology, meteorology, soil, biology, carbon flux, pollution, remote sensing, analysis and testing, survey voyage, UAV aerial photography, etc., with a data volume of 8.3tb.
      </p>

      <p>5. Research project data
      </p>

      <p>The database and special data of endangered historical data in China are 54gb, and the data of scientific research projects and achievements are 17.1gb, involving global change, big data of the earth, and special projects of basic scientific and technological work.
      </p>

      <table>

        <thead>

        <tr>

          <td></td>

          <td>Classification</td>

          <td>Time Range</td>

          <td>Sea Area</td>

          <td>Essential Factor</td>

          <td>Observation Equipment</td>

        </tr>

        </thead>

        <tbody>

        <tr>

          <td rowspan="2">Voyage survey</td>

          <td>Special</td>

          <td>2015～2018</td>

          <td>Western Pacific, Bohai Sea and South China Sea</td>

          <td rowspan="2">Chlorophyll, pH</td>

          <td rowspan="5">CTD, ADCP, hpack, ROV, beam, automatic weather station, etc</td>

        </tr>

        <tr>

          <td>Open to public use</td>

          <td>2006～2014</td>

          <td>The Indian Ocean</td>

        </tr>

        <tr>

          <td rowspan="2">Fixed point observation</td>

          <td>Buoy</td>

          <td>2009~</td>

          <td>Yellow Sea and East China Sea</td>

          <td>Meteorology, hydrology and water quality</td>

        </tr>

        <tr>

          <td>Submarine buoy</td>

          <td>2018~</td>

          <td>Western Pacific</td>

          <td>Warm salt flow</td>

        </tr>

        <tr>

          <td>Coastal zone</td>

          <td></td>

          <td></td>

          <td></td>

        </tr>

        <tr>

          <td rowspan="3">Model data</td>

          <td>ROMS</td>

          <td>1986～2018</td>

          <td></td>

          <td>Typhoon disaster</td>

          <td></td>

        </tr>

        <tr>

          <td>The Sunken ship sank</td>

          <td>2017</td>

          <td></td>

          <td>Oil spill path</td>

          <td></td>

        </tr>

        <tr>

          <td>GLOM mode</td>

          <td></td>

          <td>Global</td>

          <td>Ocean current</td>

          <td></td>

        </tr>

        </tbody>

      </table>

    </div>

  </div>

</template>

<script>
export default {
  name: 'data-sharing-info'
}
</script>

<style lang="scss" scoped>
.app-intro {
  margin: {
    bottom: 20px;
    left: auto;
    right: auto;
    top: 50px;
  };
  padding: {
    top: 20px;
  };
  width: 1200px;
}
</style>

<style>
@import url('../../assets/rich-text.css');

.rich-text h3 {
  text-align: center;
}
</style>
